<template>
  <!-- 待退押金 -->
  <div class="rent-car">
    <div class="tree">
      <header>距还车时间</header>
      <div class="tabs">
        <p
          v-for="(item, index) in timeList"
          :key="index"
          :class="['time', active == index ? 'active' : '']"
          @click="clickTime(index)"
        >
          {{ item }}
        </p>
      </div>
      <el-tree
        :expand-on-click-node="false"
        :default-expand-all="true"
        :data="carTreeList"
        :render-content="renderContent"
        @node-click="checkedNodeTree"
      ></el-tree>
    </div>
    <div class="refund-car">
      <div v-for="item in orderList" :key="item.id" class="card">
        <div class="addressMoneyInfo">
          <span style="font-size: 16px; font-weight: 600" class="text"
            >订单编号</span
          >
          <span class="money">￥{{ item.totalPrices }}</span>
        </div>
        <div style="padding: 0 16px; margin: 12px 0" class="label">
          {{ item.id }}
        </div>
        <div class="carTime">
          下单时间
          <span>{{ item.createTime }}</span>
        </div>
        <div class="addressBox">
          <div class="car-cph">
            <div>车牌</div>
            <div>{{ item.vehiclePlateNumber }}</div>
          </div>
          <div class="car-info">
            <div class="info">
              <div class="carBox">
                <span class="car-name">{{ item.rentalModelName }}</span>
              </div>
              <p class="car-main">{{ item.carInfo }}</p>
            </div>
          </div>
          <div class="car-time">
            <span>{{ item.getVehicleTime }}</span>
            <div class="pic">
              <img :src="right" alt />
              <span>共{{ item.days }}天</span>
            </div>
            <span>{{ item.returnVehicleTime }}</span>
          </div>
          <div style="margin-bottom: 16px" class="car-address">
            <div style="background: #00c777" class="tips">取车点</div>
            <div
              class="get-renturn-address"
              @click="addressChangeFun(item.pickLocationInfo)"
            >
              <span class="location-name"> {{ item.pickLocationName }} </span>
              <i class="iconfont its_sm_dakaiditu"></i>
            </div>
          </div>
          <div class="car-address">
            <span style="background: #ff8627" class="tips">还车点</span>
            <span
              class="get-renturn-address"
              @click="addressChangeFun(item.returnLocationInfo)"
            >
              <span class="location-name"> {{ item.returnLocationName }} </span>
              <i class="iconfont its_sm_dakaiditu"></i>
            </span>
          </div>
        </div>
        <div>
          <h3>费用信息</h3>
          <div class="row">
            <span class="text">租车押金：</span>
            <span class="money">￥{{ item.vehicleDeposit }}</span>
            <span v-if="!item.vehicleDepositStatus" class="refund">待退</span>
            <span v-if="item.vehicleDepositStatus" class="refunded"
              >已退 ￥{{ item.vehicleDepositRefund }}</span
            >
            <span
              v-if="item.vehicleDepositStatus"
              class="el-icon-warning"
              @click="referDetial(item)"
            ></span>
          </div>
          <div class="row">
            <span class="text">违章押金：</span>
            <span class="money">￥{{ item.violationDeposit }}</span>
            <span class="refund">待退</span>
          </div>
        </div>
        <h3>承租人信息</h3>
        <div class="row">
          <span class="text">承租人：</span>
          <span class="label">{{ item.rentalDriverName }}</span>
        </div>
        <div class="row">
          <span class="text">身份证：</span>
          <span class="label">{{ item.idCard }}</span>
        </div>
        <div class="row">
          <span class="text">联系电话：</span>
          <span class="label">{{ item.rentalDriverPhone }}</span>
        </div>
        <div class="btnBox">
          <el-button
            v-if="!item.vehicleDepositStatus && listFind('退租车押金')"
            size="small"
            type="danger"
            @click="refundZCFun(item)"
            >退租车押金</el-button
          >
          <el-button
            v-if="
              item.vehicleDepositStatus &&
              listFind('退违章押金') &&
              !item.violationDepositStatus
            "
            size="small"
            type="danger"
            plain
            @click="refundWZFun(item)"
            >退违章押金</el-button
          >
        </div>
      </div>
    </div>
    <div v-if="mapShow" class="mapMask">
      <div class="header">
        <div
          class="iconfont its_guanbi"
          @click="
            () => {
              mapShow = false;
            }
          "
        ></div>
      </div>
      <div id="Map" class="Map"></div>
    </div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      @close="cancelFun"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="right"
      >
        <el-form-item
          :label="title == '退租车押金' ? '租车押金：' : '违章押金：'"
          >￥{{ form.price }}</el-form-item
        >
        <el-form-item
          label="扣除金额："
          :rules="{
            required: true,
            validator: (rule, value, callback) => {
              this.inputNumber(rule, value, callback);
            },
            trigger: 'change',
          }"
          prop="refund"
        >
          <div class="content">
            <el-input
              v-model.trim="form.refund"
              type="number"
              :disabled="disabled"
            ></el-input>
            <div>
              <label>退还金额：</label>
              <span>￥{{ form.refundFee || 0 }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="扣除原因："
          :rules="{
            required: this.form.refund == 0 ? false : true,
            message: '请输入扣除原因',
            trigger: 'blur',
          }"
          prop="reason"
        >
          <el-input
            v-model="form.reason"
            type="textarea"
            :maxlength="200"
            :rows="3"
            :disabled="disabled"
            placeholder="请输入扣除原因"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-if="!disabled" slot="footer" class="dialog-footer">
        <el-button @click="cancelFun">取 消</el-button>
        <el-button type="primary" @click="confirmFun">确 定</el-button>
      </div></el-dialog
    >
  </div>
</template>

<script>
import {
  waitingRefundDepositAPI,
  serverRefundVehicleDepositAPI,
  serverRefundViolationDepositAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
import moment from "moment";
import right from "@/assets/images/home/right.png";
export default {
  data() {
    return {
      carTreeList: [],
      timeList: ["7天内", "15天内", "超过15天", "全部"],
      active: 0,
      orderList: [],
      mapShow: false,
      right: right,
      map: null,
      show: false,
      title: "",
      disabled: false,
      form: {
        orderId: "",
        price: 0,
        refund: 0,
        reason: "",
        refundFee: 0,
      },
    };
  },
  mounted() {
    this.clickTime(0);
  },
  methods: {
    clickTime(index) {
      this.active = index;
      const date = new Date();
      let obj = {};
      switch (index) {
        case 0:
          obj.endDay = moment(date).format("YYYY-MM-DD");
          obj.startDay = moment(date).subtract(7, "days").format("YYYY-MM-DD");
          break;
        case 1:
          obj.endDay = moment(date).format("YYYY-MM-DD");
          obj.startDay = moment(date).subtract(15, "days").format("YYYY-MM-DD");
          break;
        case 2:
          obj.endDay = moment(date).format("YYYY-MM-DD");
          break;
        case 3:
          break;
        default:
          break;
      }
      this.renderData(obj);
    },
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    renderData(date) {
      this.orderList = [];
      waitingRefundDepositAPI(date).then((res) => {
        if (res.code == "SUCCESS") {
          let list = [];
          let list2 = [];
          res.data.forEach((item) => {
            let arrays = [];
            item.children.forEach((jump) => {
              arrays.push({
                label: jump.nodeName,
                nodeOrderCount: jump.nodeOrderCount,
                id: jump.pickLocationId,
                rentalOrders: jump.rentalOrders,
              });
            });
            list.push({
              label: item.nodeName,
              children: arrays,
              nodeOrderCount: item.nodeOrderCount,
              id: item.pickLocationId,
              rentalOrders: item.rentalOrders,
            });
            if (item.rentalOrders.length > 0) {
              list2 = item.rentalOrders.map((e) => {
                let info = e.rentalVehicleModel || false;
                if (info) {
                  e.carInfo = `${info.gearbox} ${info.displacement}${
                    info.intakeFormCode == "WLZY" ? "T" : "L"
                  }/${info.carBodyStructure}/${info.vehicleTypeName}`;
                }
                e.idCard = this.decryptDes(e.idCardNo).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
                e.carLogo = host.imgURL + e.rentalVehicleModel.picture;
                return { ...e };
              });
              this.orderList.push(...list2);
            }
          });
          this.carTreeList = list;
        }
      });
    },
    renderContent(h, { node, data, store }) {
      if (data.nodeOrderCount) {
        return (
          <span>
            <span>{data.label}</span>
            <span style="color:red"> ({data.nodeOrderCount})</span>
          </span>
        );
      } else {
        return <span>{data.label}</span>;
      }
    },
    checkedNodeTree(data) {
      let list = [];
      this.orderList = [];
      if (data.rentalOrders && data.rentalOrders.length > 0) {
        list = data.rentalOrders.map((e) => {
          let info = e.rentalVehicleModel || false;
          if (info) {
            e.carInfo = `${info.gearbox} ${info.displacement}${
              info.intakeFormCode == "WLZY" ? "T" : "L"
            }/${info.carBodyStructure}/${info.vehicleTypeName}`;
          }
          e.idCard = this.decryptDes(e.idCardNo).replace(
            /^(.{4})(?:\d+)(.{3})$/,
            "$1******$2"
          );
          e.carLogo = host.imgURL + e.rentalVehicleModel.picture;
          return { ...e };
        });
      }
      this.orderList.push(...list);
    },
    // 实例化地图
    initMap(lon, lat) {
      this.map = new AMap.Map("Map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        center: [lon, lat],
        zoom: 10,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      var marker = new AMap.Marker({
        offset: new AMap.Pixel(-18, -18), //设置偏移量
        position: new AMap.LngLat(lon, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        icon: require("../../../../assets/images/home/passenger.png"),
      });
      this.map.add(marker);
      this.map.setFitView(marker);
    },
    // 点击详细地址的时候打开地图
    addressChangeFun(obj) {
      const { latitude, longitude } = obj;
      this.mapShow = true;
      this.$nextTick(() => {
        this.initMap(longitude, latitude);
      });
    },
    refundZCFun(item) {
      this.title = "退租车押金";
      this.show = true;
      this.disabled = false;
      this.form.reason = "";
      this.form.refund = 0;
      this.$nextTick(() => {
        this.form.orderId = item.id;
        this.form.price = item.vehicleDeposit;
        this.form.refundFee = item.vehicleDeposit;
        this.form.refund = 0;
        this.form.reason = "";
      });
    },
    referDetial(item) {
      this.title = "退租车押金";
      this.show = true;
      this.disabled = true;
      this.$nextTick(() => {
        this.form.orderId = item.id;
        this.form.price = item.vehicleDeposit;
        this.form.refundFee = item.vehicleDepositRefund;
        this.form.refund = item.vehicleDepositDeduct;
        this.form.reason = item.vehicleDepositDeductRemark;
      });
    },
    refundWZFun(item) {
      this.title = "退违章押金";
      this.show = true;
      this.disabled = false;
      this.form.reason = "";
      this.form.refund = 0;
      this.$nextTick(() => {
        this.form.orderId = item.id;
        this.form.price = item.violationDeposit;
        this.form.refundFee = item.violationDeposit;
        this.form.refund = 0;
        this.form.reason = "";
      });
    },
    inputNumber(rule, value, callback) {
      if (value === "") callback("请输入扣除金额");
      else if (value > this.form.price) callback("扣除金额不得大于押金");
      else if (/^([1-9]|0)*(.\d{1,2})?$/.test(value)) {
        this.form.refundFee = (this.form.price * 100 - value * 100) / 100;
        callback();
      } else callback("请输入整数/两位小数");
    },
    cancelFun() {
      this.show = false;
      this.form.reason = "";
      this.form.refund = 0;
    },
    confirmFun() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {
            orderId: this.form.orderId,
            refundFee: this.form.refundFee,
            reason: this.form.reason,
          };
          if (this.title == "退租车押金") {
            serverRefundVehicleDepositAPI(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("成功退还租车押金");
                this.show = false;
                this.clickTime(0);
              }
            });
          } else {
            serverRefundViolationDepositAPI(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("成功退还违章押金");
                this.show = false;
                this.clickTime(0);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rent-car {
  height: 100%;
  background: #f7f8f9;
  display: flex;
  .mapMask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .tree {
    width: 300px;
    margin-right: 20px;
    box-shadow: 0px 0px 1px 0px #b4b2b2;
    transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -webkit-transition: box-shadow 0.3s;
    height: 100%;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 0;
    header {
      height: 34px;
      font-size: 15px;
      line-height: 34px;
      text-align: center;
    }
    .tabs {
      display: flex;
      padding-bottom: 10px;
      .active {
        background-color: #336ffe;
        color: #fff;
      }
      .time {
        flex: 1;
        box-sizing: border-box;
        margin: 0 5px;
        font-size: 13px;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    /deep/.el-tree-node {
      white-space: normal;
      .el-tree-node__content {
        height: 100%;
      }
    }
  }
  .refund-car {
    flex: 1;
    height: 100%;
    border: 1px solid #dbe2f3;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: 605px;
    flex: 1;
    .card {
      border-radius: 2px;
      border: 1px solid #e9e9e9;
      background: #ffffff;
      width: 290px;
      // width: calc((100% - 80px) / 4);
      margin-right: 24px;
      margin-bottom: 20px;
      position: relative;
      min-height: 620px;
      .addressBox {
        padding: 16px 12px;
        background: #f3f5fa;
        margin: 12px 0 16px 0;
        .car-cph {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          & > div:nth-child(1) {
            background: #336ffe;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            padding: 6px 10px;
            margin-right: 8px;
          }
          & > div:nth-child(2) {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
          }
        }
        .car-info {
          display: flex;
          align-items: center;
          .car {
            width: 73px;
            height: 34px;
          }
          .info {
            margin-left: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
            .car-name {
              color: #333333;
              font-weight: bold;
              margin-bottom: 12px;
            }
            .car-main {
              font-size: 12px;
              color: #999;
            }
          }
        }
        .car-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          margin: 20px 0 16px;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          .pic {
            position: relative;
            width: 68px;
            img {
              width: 50px;
            }
            span {
              display: inline-block;
              text-align: center;
              width: 100%;
              position: absolute;
              left: -5px;
              bottom: 6px;
              color: #336ffe;
              font-size: 10px;
            }
          }
        }
        .car-address {
          display: flex;
          align-items: flex-start;
          .tips {
            display: inline-block;
            padding: 3px 8px;
            background: #336ffe;
            color: #fff;
            font-size: 12px;
            border-radius: 2px;
          }
          .get-renturn-address {
            margin: 0 6px;
            display: flex;
            cursor: pointer;
            .location-name {
              display: inline-block;
              width: 185px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
          .its_sm_dakaiditu {
            color: #448bfb;
            font-size: 12px;
            margin-left: 2px;
            position: relative;
            top: 1px;
          }
        }
      }
      .btnBox {
        width: calc(100% - 24px);
        border-top: 1px solid #f2f2f2;
        padding: 12px;
        text-align: right;
        background: #ffffff;
        margin-top: 12px;
        position: absolute;
        bottom: 0;
      }
      h3 {
        padding: 0 15px;
      }
      .row {
        padding: 0 16px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        .text {
          color: #86888b;
          font-size: 12px;
          font-weight: 400;
          width: 60px;
          text-align: right;
          display: inline-block;
        }

        .label {
          display: inline-block;
          width: calc(100% - 60px);
          color: #333333;
          font-size: 14px;
          font-weight: 400;
        }
        .money {
          color: rgb(255, 166, 0);
          font-size: 16px;
          font-weight: 400;
        }
        .refund {
          color: red;
          margin-left: 20px;
        }
        .refunded {
          color: rgb(5, 199, 5);
          margin-left: 20px;
        }
        .el-icon-warning {
          font-size: 18px;
          color: rgb(5, 199, 5);
          margin-left: 20px;
          cursor: pointer;
        }
      }
      .carTime {
        font-weight: 400;
        color: #336ffe;
        font-size: 14px;
        padding: 0 16px;
      }
      .addressMoneyInfo {
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 16px;
        .money {
          font-weight: 600;
          color: #ff8627;
          font-size: 16px;
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .el-input {
      width: 200px;
    }
    span {
      color: red;
      margin-right: 30px;
    }
  }
}
</style>
